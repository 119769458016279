import './InfoUser.css';
import DateObject from "react-date-object";

export function InfoCycle({ cycle, subcycle, showEdition }) {

    function CurrentSubCycle() {
        function TableObjectifs({ array, name }) {
            const iterateItem = (items) => {
                return items.map((item) => (
                    <tr key={(Math.random() + 1).toString(36).substring(7)}>
                        <td className='InfoUser_cycle_objectif'>
                            {item.name}
                            {item.info !== "" ?
                                <span infoBulle={item.info} className="material-symbols-outlined">info</span>
                                :
                                <></>
                            }
                        </td>
                    </tr>
                ))
            }

            if (array.length > 0) {
                return (
                    <>
                        <tr>
                            <td className='InfoUser_cycle_subtitle'>{name} : </td>
                        </tr>
                        {iterateItem(array)}
                    </>
                )
            }

            return (
                <></>
            )
        }

        if (subcycle != null) {
            let d = new DateObject(subcycle.debut);
            let f = (new DateObject(subcycle.fin)).add(6, "days");
            return (
                <>
                    <tr className='InfoUser_button'>
                        <td> </td>
                    </tr>
                    <tr className='InfoUser_cycle_title'>
                        <td>Sous Cycles :</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>{subcycle.name}</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>du {d.format("DD/MM/YYYY")} au {f.format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr className='InfoUser_cycle_title'>
                        <td>Objectif :</td>
                    </tr>
                    <TableObjectifs array={subcycle.physics} name={"Renforcements musculaire"} />
                    <TableObjectifs array={subcycle.climbings} name={"Escalade"} />
                    <TableObjectifs array={subcycle.technics} name={"Techniques, tactiques et mentals"} />
                    <TableObjectifs array={subcycle.others} name={"Autres"} />
                    <tr>
                        <td> </td>
                    </tr>
                </>
            )
        }
        return (
            <>
                <tr className='InfoUser_button'>
                    <td> </td>
                </tr>
                <tr className='InfoUser_cycle_title'>
                    <td>Sous Cycles :</td>
                </tr>
                <tr className='InfoUser_cycle_nocycle'>
                    <td>Pas de sous cycle en cours</td>
                </tr>
                <tr>
                    <td> </td>
                </tr>
            </>
        )
    }

    function CurrentCycle() {
        if (cycle != null) {
            return (
                <>
                    <tr className='InfoUser_button'>
                        <td> </td>
                    </tr>
                    <tr className='InfoUser_cycle_title'>
                        <td>Cycles :</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>{cycle.name}</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>du {cycle.debut.format("DD/MM/YYYY")} au {cycle.fin.format("DD/MM/YYYY")}</td>
                    </tr>
                    <CurrentSubCycle />
                    <tr>
                        <td> </td>
                    </tr>
                    <tr className='InfoUser_button'>
                        <td><button onClick={() => showEdition()}>Editions des Cycles</button></td>
                    </tr>
                    <tr className='InfoUser_button'>
                        <td> </td>
                    </tr>
                </>
            )
        }
        return (
            <>
                <tr className='InfoUser_button'>
                    <td> </td>
                </tr>
                <tr className='InfoUser_cycle_title'>
                    <td>Cycles :</td>
                </tr>
                <tr className='InfoUser_cycle_nocycle'>
                    <td>Pas de cycle en cours</td>
                </tr>
                <tr>
                    <td> </td>
                </tr>
                <tr className='InfoUser_button'>
                    <td><button onClick={() => showEdition()}>Editions des Cycles</button></td>
                </tr>
                <tr className='InfoUser_button'>
                    <td> </td>
                </tr>
            </>
        )
    }

    return (
        <table className='InfoUser_table'>
            <tbody>
                <CurrentCycle />
            </tbody>
        </table>
    )
}

export function InfoCycle2({ cycle, subcycle, sessions, showEdition, date, hide }) {
    let weekIndex = 0;

    let objectifs = new Map()

    if (subcycle !== null) {
        subcycle.physics.forEach(element => {
            objectifs.set(element.id, 0);
        });

        subcycle.climbings.forEach(element => {
            objectifs.set(element.id, 0);
        });

        subcycle.technics.forEach(element => {
            objectifs.set(element.id, 0);
        });

        subcycle.others.forEach(element => {
            objectifs.set(element.id, 0);
        });

        for (let i = 0; i < subcycle.weeks; i++) {
            let date_d = new DateObject(subcycle.start).add(7 * i, 'days');
            let date_f = new DateObject(date_d).add(7, 'days');
            if (date.format() >= date_d.format() && date.format() < date_f.format()) {
                weekIndex = i;
                break;
            }
        }
    }

    sessions.forEach(element => {
        element.objectifs.forEach(element2 => {
            let a = element2.value;
            if (objectifs.has(a)) {
                objectifs.set(a, (objectifs.get(a) + 1))
            }
        });
    });

    function CurrentSubCycle() {
        function TableObjectifs({ array, name }) {
            const iterateItem = (items) => {
                return items.map((item) => (
                    <tr key={(Math.random() + 1).toString(36).substring(7)}>
                        <td className='InfoUser_cycle_objectif'>
                            {item.name} : {objectifs.get(item.id)}/{item.week[weekIndex]}
                            {item.info !== "" ?
                                <span infoBulle={item.info} className="material-symbols-outlined">info</span>
                                :
                                <></>
                            }
                        </td>
                    </tr>
                ))
            }

            if (array.length > 0) {
                return (
                    <>
                        <tr>
                            <td className='InfoUser_cycle_subtitle'>{name} : </td>
                        </tr>
                        {iterateItem(array)}
                    </>
                )
            }

            return (
                <></>
            )
        }

        function TableObjectifs2({ array, name }) {
            const iterateItem = (items) => {
                return items.map((item) => (
                    <tr key={(Math.random() + 1).toString(36).substring(7)}>
                        <td className='InfoUser_cycle_objectif'>
                            {item.name} : ?
                            {item.info !== "" ?
                                <span infoBulle={item.info} className="material-symbols-outlined">info</span>
                                :
                                <></>
                            }
                        </td>
                    </tr>
                ))
            }

            if (array.length > 0) {
                return (
                    <>
                        <tr>
                            <td className='InfoUser_cycle_subtitle'>{name} : </td>
                        </tr>
                        {iterateItem(array)}
                    </>
                )
            }

            return (
                <></>
            )
        }

        if (subcycle != null) {
            return (
                <>
                    <tr className='InfoUser_button'>
                        <td> </td>
                    </tr>
                    <tr className='InfoUser_cycle_title'>
                        <td>Sous Cycles :</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>{subcycle.name}</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>du {subcycle.start.format("DD/MM/YYYY")} au {subcycle.end.format("DD/MM/YYYY")}</td>
                    </tr>
                    <tr className='InfoUser_cycle_title'>
                        <td>Objectif :</td>
                    </tr>
                    <TableObjectifs array={subcycle.physics} name={"Renforcements musculaire"} />
                    <TableObjectifs array={subcycle.climbings} name={"Escalade"} />
                    <TableObjectifs2 array={subcycle.technics} name={"Techniques, tactiques et mentals"} />
                    <TableObjectifs array={subcycle.others} name={"Autres"} />
                    <tr>
                        <td> </td>
                    </tr>
                </>
            )
        }
        return (
            <>
                <tr className='InfoUser_button'>
                    <td> </td>
                </tr>
                <tr className='InfoUser_cycle_title'>
                    <td>Sous Cycles :</td>
                </tr>
                <tr className='InfoUser_cycle_nocycle'>
                    <td>Pas de sous cycle en cours</td>
                </tr>
                <tr>
                    <td> </td>
                </tr>
            </>
        )
    }

    function CurrentCycle() {
        if (cycle != null) {
            return (
                <>
                    <tr className='InfoUser_button'>
                        <td> </td>
                    </tr>
                    <tr className='InfoUser_cycle_title'>
                        <td>Cycles :</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>{cycle.name}</td>
                    </tr>
                    <tr className='InfoUser_cycle_nocycle'>
                        <td>du {cycle.debut.format("DD/MM/YYYY")} au {cycle.fin.format("DD/MM/YYYY")}</td>
                    </tr>
                    <CurrentSubCycle />
                    <tr>
                        <td> </td>
                    </tr>
                    <tr className='InfoUser_button'>
                        <td><button onClick={() => { showEdition() }}>Editions des Cycles</button></td>
                    </tr>
                    <tr className='InfoUser_button'>
                        <td> </td>
                    </tr>
                </>
            )
        }
        return (
            <>
                <tr className='InfoUser_button'>
                    <td> </td>
                </tr>
                <tr className='InfoUser_cycle_title'>
                    <td>Cycles :</td>
                </tr>
                <tr className='InfoUser_cycle_nocycle'>
                    <td>Pas de cycle en cours</td>
                </tr>
                <tr>
                    <td> </td>
                </tr>
                <tr className='InfoUser_button'>
                    <td><button onClick={() => { showEdition() }}>Editions des Cycles</button></td>
                </tr>
                <tr className='InfoUser_button'>
                    <td> </td>
                </tr>
            </>
        )
    }

    return (
        <table className='InfoUser_table'>
            <tbody>
                <tr><td className='InfoUser_cell_title'>Info cycle <span onClick={hide} className="material-symbols-outlined InfoUser_cell_title_close">arrow_back</span></td></tr>
                <CurrentCycle />
            </tbody>
        </table>
    )
}