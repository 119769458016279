import './Colonne.css'
import DateObject from "react-date-object";

export function Colonne({ date, sessions, trainee, showSession}) {
    const month_name = ['janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'];
    const day_name = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

    //const [current_session, setCurrentSession] = useState(null);
    //const [show_session, setShowSession] = useState(false);
    //const [current_session_id, setCurrentSessionId] = useState("");
    //const [show_edit_session, setShowEditSession] = useState(false);

   /*function toggleSession(id) {
        if (current_session_id === id) {
            setCurrentSessionId("")
        } else {
            setCurrentSessionId(id)
        }
    }*/

    /*function showEditSession(s) {
        let session = {
            id: s.id,
            name: s.name,
            date: s.date,
            objectifs: s.objectifs,
            time: s.time,
            exercices: [],
        }
        s.exercices.forEach((e) => {
            const blocksFromHtml = htmlToDraft(e.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            session.exercices.push({
                id: e.id,
                name: e.name,
                time: e.time,
                files: e.files,
                description: editorState
            })
        })
        setCurrentSession(session);
        setShowEditSession(true);
    }

    function hideEditSession() {
        setShowEditSession(false);
    }

    function showSession(s) {
        setCurrentSession(s);
        setShowSession(true);
    }

    function hideSession() {
        setShowSession(false);
    }*/

    function dateToFrenchDateString(d) {
        let s = "";
        s += day_name[d.weekDay.index];
        s += " ";
        s += d.format("D");
        s += " ";
        s += month_name[d.monthIndex];
        s += " ";
        s += d.format("YYYY");

        return s;
    }

    function dateToFrenchHourString(d) {
        let s = "";
        if (d.hour < 10) {
            s += "0"
        }
        s += d.hour;
        s += "h";
        if (d.minute < 10) {
            s += "0"
        }
        s += d.minute;

        return s;
    }

    function timeToString(t) {
        let s = "";
        let m = t;
        let h = Math.floor(m / 60.0);
        m -= h * 60;

        if (h > 0) {
            s += h + "h";
            if (m > 0) {
                s += m;
            }
        } else {
            s += m + "min";
        }

        return s;
    }

    let sessions_sort = sessions
    sessions_sort.sort((a, b) => {
        if (a.date < b.date) {
            return -1;
        } else if (a.date > b.date) {
            return 1;
        }

        return 0;
    });


    let day_in_week = date.weekDay.index;
    if (day_in_week <= 0)
        day_in_week = 7;
    day_in_week--;
    let monday = new DateObject(date).add(-day_in_week, "d").setHour(0).setMinute(0).setSecond(0);

    let sessions_by_day = []
    for (let i = 0; i < 7; i++) {
        let sessions_in_day = []
        let day = new DateObject(monday).add(i, "d");
        sessions_sort.forEach(element => {
            if (element.date.format() === day.format()) {
                sessions_in_day.push(element)
            }
        });
        sessions_by_day.push(sessions_in_day);
    }

    function Session({ session }) {
        /*function Exercice({ exercice }) {
            return (
                <>
                    <tr>
                        <td className='Colonne_exercice_title' colSpan={2}>{exercice.name}</td>
                    </tr>
                    <tr>
                        <td className='Colonne_exercice_time' colSpan={2}><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(exercice.time)}</td>
                    </tr>
                    <tr>
                        <td className='Colonne_exercice_time' colSpan={2}><span className='Colonne_session_subtitle'>Description</span> :</td>
                    </tr>
                    <tr>
                        <td className='Colonne_exercice_description' colSpan={2} dangerouslySetInnerHTML={{ __html: exercice.description }}></td>
                    </tr>
                </>
            )
        }*/

        /*const iterateExercice = (list) => {
            return list.map((e) => (
                <Exercice key={e.id} exercice={e} />
            ))
        }*/

        /*if (current_session_id === session.id) {
            return (
                <tr><td className='Colonne_table_session'>
                    <table className='Colonne_session_table' onDoubleClick={() => showEditSession(session)}>
                        <tbody>
                            <tr>
                                <td className='Colonne_session_hour'>{dateToFrenchHourString(session.date)}</td>
                                <td rowSpan={3} className='Colonne_session_arrow' onClick={() => toggleSession(session.id)}><span className="material-symbols-outlined">arrow_drop_down</span></td>
                            </tr>
                            <tr>
                                <td className='Colonne_session_title'>{session.name}</td>
                            </tr>
                            <tr>
                                <td className='Colonne_session_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(session.time)}</td>
                            </tr>
                            <tr>
                                <td className='Colonne_session_time' colSpan={2}><span className='Colonne_session_subtitle'>Exercices</span> :</td>
                            </tr>
                            {iterateExercice(session.exercices)}
                        </tbody>
                    </table>
                </td></tr>
            )
        }*/

        return (
            <tr><td className='Colonne_table_session'>
                <table className='Colonne_session_table' onClick={() => showSession(session)}>
                    <tbody>
                        <tr>
                            <td className='Colonne_session_hour'>{dateToFrenchHourString(session.date)}</td>
                            <td rowSpan={3} className='Colonne_session_arrow'><span className="material-symbols-outlined">chevron_right</span></td>
                        </tr>
                        <tr>
                            <td className='Colonne_session_title'>{session.name}</td>
                        </tr>
                        <tr>
                            <td className='Colonne_session_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(session.time)}</td>
                        </tr>
                    </tbody>
                </table>
            </td></tr>
        )
    }

    function Week() {
        let week = []
        for (let i = 0; i < 7; i++) {
            let day = new DateObject(monday).add(i, "d");
            week.push(<tr key={"date" + day.format()}>
                <td className='Colonne_row_date'>{dateToFrenchDateString(day)}</td>
            </tr>)
            for (let j = 0; j < sessions_by_day[i].length; j++) {
                week.push(<Session key={"session" + sessions_by_day[i][j].id} session={sessions_by_day[i][j]} />)
            }
        }

        return (
            <>{week}</>
        )
    }

    if(trainee === null){
        return (
            <div className='Colonne'></div>
        )
    }

    return (
        <div className='Colonne'>
            <table className='Colonne_table'>
                <tbody>
                    <Week />
                </tbody>
            </table>
        </div>
    )
}