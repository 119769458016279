import './MainView.css';
import { DateContext, TraineeContext } from './Context.js';
import { useState, useEffect, useRef, useCallback } from 'react';
import DateObject from "react-date-object";
import { db, auth } from "./firebase.js";

import {/*InfoCycle,*/ InfoCycle2 } from './InfoCycle.js';
import { InfoUser } from './InfoUser.js';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { PlanifView } from './PlanifView.js';
import { AddTrainee } from './AddTrainee.js';
import { EditionCycles } from './EditionCycles.js';
import { EditSession } from './EditSession.js';
import { CompleteSession } from './Session.js';

import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js';
import { AddSession } from './AddSession.js';
import { CopySession } from './CopySession.js';

export function MainView() {
    const Menu = {
        Planif: 'Planif',
        Exercices: 'Exercices',
        CyclesEditions: 'CyclesEditions',
        InfoCycle: 'InfoCycle',
        InfoTrainee: 'InfoTrainee',
        Session: 'Session',
        AddSession: 'AddSession',
        EditSession: 'EditSession',
        CopySession: 'CopySession',
        AddTrainee: 'AddTrainee',
    };

    const [state, setState] = useState(Menu.Planif);

    const [menu, setMenu] = useState(false);
    const [trainee_choser, setTraineeChooser] = useState(false);

    const [date, setDate] = useState(new DateObject());

    const [trainees, setTrainees] = useState([]);
    const [traineeSelected, selectTrainee] = useState("");
    const [sessions, setSessions] = useState([]);

    const [current_cycle, setCurrentCycle] = useState(null);
    const [current_sub_cycle, setCurrentSubCycle] = useState(null);
    const [current_trainee, setCurrentTrainee] = useState(null);
    const [current_session, setCurrentSession] = useState(null);
    const [copy_session, setCopySession] = useState(null);

    const cycleListener = useRef(null);
    const subcycleListener = useRef(null);
    const sessionsListener = useRef(null);

    function toCyclesEditions() {
        setState(Menu.CyclesEditions);
    }

    function toPlanif() {
        setState(Menu.Planif);
    }

    function toInfoCycle() {
        setState(Menu.InfoCycle);
    }

    function toInfoTrainee() {
        setState(Menu.InfoTrainee);
    }

    function toSession(session) {
        setCurrentSession(session);
        setState(Menu.Session);
    }

    function toEditSession(s) {
        let session = {
            id: s.id,
            name: s.name,
            date: s.date,
            objectifs: s.objectifs,
            time: s.time,
            exercices: [],
        }
        s.exercices.forEach((e) => {
            const blocksFromHtml = htmlToDraft(e.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            session.exercices.push({
                id: e.id,
                name: e.name,
                time: e.time,
                files: e.files,
                description: editorState
            })
        })
        setCurrentSession(session);
        setState(Menu.EditSession);
    }

    function toCopySession() {
        let session = {
            name: current_session.name,
            date: current_session.date,
            objectifs: [],
            time: current_session.time,
            exercices: [],
        }
        current_session.exercices.forEach((e) => {
            const blocksFromHtml = htmlToDraft(e.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);

            session.exercices.push({
                id: e.id,
                name: e.name,
                time: e.time,
                files: e.files,
                description: editorState
            })
        })
        setCopySession(session);
        setState(Menu.CopySession);
    }

    function toAddSession() {
        setState(Menu.AddSession);
    }

    function toAddTrainee() {
        setState(Menu.AddTrainee);
    }



    function compare(a, b) {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
        }
        return 0;
    };

    const lookForCurrentTrainee = useCallback(() => {
        trainees.forEach((t) => {
            if (t.id === traineeSelected) {
                setCurrentTrainee(t);
            }
        });
    }, [traineeSelected, trainees]);

    function loadTrainee(id) {
        loadTraineeInfo(id)
        lookForCurrentTrainee();
        selectTrainee(id);
        localStorage.setItem("traineeId", id);
    }

    const loadTraineeInfo = useCallback((id) => {
        setCurrentCycle(null);
        setCurrentSubCycle(null);
        setSessions([]);
        if (subcycleListener.current != null) {
            subcycleListener.current();
        }
        if (cycleListener.current != null) {
            cycleListener.current();
        }
        cycleListener.current = db.collection("Trainees").doc(id).collection("Cycles").where("debut", "<=", date.format()).where("fin", ">=", date.format())
            .onSnapshot((snapshot) => {
                let a = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    name: doc.data().name,
                    debut: new DateObject(doc.data().debut),
                    fin: (new DateObject(doc.data().fin)).add(6, "d").setHour(23).setMinute(59).setSecond(59),
                }))
                if (a.length > 0) {
                    setCurrentCycle(a[0]);
                    subcycleListener.current = db.collection("Trainees").doc(id).collection("Cycles").doc(a[0].id).collection("SubCycles").where("start", "<=", date.format()).where("end", ">=", date.format())
                        .onSnapshot((snapshot) => {
                            let a = snapshot.docs.map((doc) => ({
                                id: doc.id,
                                name: doc.data().name,
                                start: new DateObject(doc.data().start),
                                end: (new DateObject(doc.data().end)).setHour(23).setMinute(59).setSecond(59),
                                weeks: doc.data().weeks,
                                loads: doc.data().loads,
                                physics: doc.data().physics,
                                climbings: doc.data().climbings,
                                technics: doc.data().technics,
                                others: doc.data().others,
                            }))
                            if (a.length > 0) {
                                setCurrentSubCycle(a[0]);
                            } else {
                                setCurrentSubCycle(null);
                            }
                        });
                } else {
                    if (subcycleListener.current != null) {
                        subcycleListener.current();
                    }
                    setCurrentCycle(null);
                    setCurrentSubCycle(null);
                }
            }
            );

        if (sessionsListener.current != null) {
            sessionsListener.current();
        }
        let day_in_week = date.weekDay.index;
        if (day_in_week <= 0)
            day_in_week = 7;
        day_in_week--;
        let monday = new DateObject(date).add(-day_in_week, "d").setHour(0).setMinute(0).setSecond(0);
        let mondayNext = new DateObject(monday).add(7, "d");

        sessionsListener.current = db.collection("Trainees").doc(id).collection("Seances").where("date", ">=", monday.format()).where("date", "<", mondayNext.format())
            .onSnapshot((snapshot) => {
                let a = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    name: doc.data().name,
                    date: new DateObject(doc.data().date),
                    time: doc.data().time,
                    objectifs: doc.data().objectifs,
                    exercices: doc.data().exercices,

                }))
                setSessions(a);
            });

    }, [date]);

    const loadTrainees = useCallback((list_id) => {
        if (list_id.length > 0) {
            db.collection("Trainees").where(firebase.firestore.FieldPath.documentId(), "in", list_id).get().then(
                (querySnapshot) => {
                    let a = []
                    querySnapshot.forEach((doc) => {
                        a.push({
                            id: doc.id,
                            name: doc.data().name
                        })
                    });
                    a.sort(compare);
                    setTrainees(a);
                    if (traineeSelected !== "" && list_id.includes(traineeSelected)) {
                        loadTraineeInfo(traineeSelected)
                        a.forEach((t) => {
                            if (t.id === traineeSelected) {
                                setCurrentTrainee(t);
                            }
                        });
                    }
                });
        }
    }, [traineeSelected, loadTraineeInfo]);


    useEffect(() => {
        let ti = localStorage.getItem("traineeId");
        selectTrainee(ti);
        let userId = auth.currentUser.uid;
        db.collection("Users").doc(userId)
            .onSnapshot((snapshot) => {
                if (snapshot.exists) {
                    if (snapshot.data().trainees) {
                        let list_id_trainees = snapshot.data().trainees;
                        loadTrainees(list_id_trainees);
                    }
                } else {
                    //db.collection("Users").doc(userId).set({ trainees: [] })
                }
            });
    }, [loadTraineeInfo, loadTrainees]);

    if (state === Menu.CyclesEditions) {
        return (
            <div className="MainView">
                <EditionCycles isVisible={true} hide={toPlanif} trainee={current_trainee} />
            </div>
        )
    }

    if (state === Menu.InfoCycle) {
        return (
            <InfoCycle2 cycle={current_cycle} subcycle={current_sub_cycle} showEdition={toCyclesEditions} sessions={sessions} date={date} hide={toPlanif} />
        )
    }

    if (state === Menu.InfoTrainee) {
        return (
            <InfoUser user={current_trainee} hide={toPlanif} />
        )
    }

    if (state === Menu.Session) {
        return (
            <CompleteSession session={current_session} showEdit={toEditSession} trainee={current_trainee} subcycle_session={current_sub_cycle} hide={toPlanif} copySession={toCopySession} />
        )
    }

    
    if (state === Menu.EditSession) {
        return (
            <EditSession session={current_session} trainee={current_trainee} subcycle_session={current_sub_cycle} hide={toPlanif} />
        )
    }

    if (state === Menu.CopySession) {
        return (
            <CopySession session={copy_session} trainee={current_trainee} trainees={trainees} hide={toPlanif} />
        )
    }

    if (state === Menu.AddSession) {
        return (
            <AddSession date_session={(new DateObject(date)).add(1, 'h').setMinute(0).setSecond(0).setMillisecond(0)} hide={toPlanif} subcycle_session={current_sub_cycle} trainee={current_trainee} />
        )
    }

    if (state === Menu.AddTrainee) {
        return (
            <AddTrainee onClose={toPlanif} />
        )
    }

    return (
        <div className="MainView">
            <header className="MainView_header">
                <table className="MainView_header_table">
                    <tbody>
                        <tr>
                            <td className='MainView_header_chooser_cell'>
                                <TraineeChooser />
                                <ModalTraineeChooser />
                            </td>
                            <td className='MainView_header_menu_cell'>
                                <span className="test material-symbols-outlined" onClick={() => { setTraineeChooser(false); if (menu) { setMenu(false) } else { setMenu(true) } }}>Menu</span>
                                <ModalMenu />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </header>
            <div className="MainView_body">
                <CurrentView />
            </div>
        </div>
    )

    function CurrentView() {
        if (state === Menu.Planif) {
            return (
                <DateContext.Provider value={{ date: date, setDate: setDate }}>
                    <TraineeContext.Provider value={{ trainee: current_trainee, cycle: current_cycle, subcycle: current_sub_cycle, sessions: sessions }}>
                        <PlanifView toCyclesEditions={toCyclesEditions} toInfoCycle={toInfoCycle} toInfoTrainee={toInfoTrainee} toSession={toSession} toAddSession={toAddSession} />
                    </TraineeContext.Provider>
                </DateContext.Provider>
            )
        }

        if (state === Menu.Exercices) {
            return (
                <span>Exercices</span>
            )
        }

        return (
            <></>
        )
    }

    function ModalMenu() {
        if (menu) {
            if (state === Menu.Exercices) {
                return (
                    <div className='MainView_modal_menu' onClick={() => setMenu(false)}>
                        <ul>
                            <li key={1} className='MainView_modal_menu_li' onClick={() => { setState(Menu.Planif); }}>Planif</li>
                            <li key={2} className='MainView_modal_menu_li_selected'>Exercices</li>
                            <li key={3} className='MainView_modal_menu_li' onClick={() => { auth.signOut(); }}>Se déconnecter</li>
                        </ul>
                    </div>
                )
            }

            if (state === Menu.Planif) {
                return (
                    <div className='MainView_modal_menu' onClick={() => setMenu(false)}>
                        <ul>
                            <li className='MainView_modal_menu_li_selected'>Planif</li>
                            <li className='MainView_modal_menu_li' onClick={() => { setState(Menu.Exercices); }}>Exercices</li>
                            <li className='MainView_modal_menu_li' onClick={() => { auth.signOut(); }}>Se déconnecter</li>
                        </ul>
                    </div>
                )
            }
        }

        return (
            <></>
        )
    }

    function TraineeChooser() {
        let t = null;
        trainees.forEach((e) => {
            if (e.id === traineeSelected) {
                t = e;
            }
        })
        if (state === Menu.Planif) {
            if (t != null) {
                return (
                    <div className='MainView_trainee_chooser' onClick={() => { setMenu(false); if (trainee_choser) { setTraineeChooser(false) } else { setTraineeChooser(true) } }}>
                        {t.name}
                        <span className="material-symbols-outlined">unfold_more</span>
                    </div>
                )
            }

            return (
                <div className='MainView_trainee_chooser_nobody' onClick={() => { setMenu(false); if (trainee_choser) { setTraineeChooser(false) } else { setTraineeChooser(true) } }}>
                    Aucune sélection
                    <span className="material-symbols-outlined">unfold_more</span>
                </div>
            )
        }
        return (
            <></>
        )
    }


    function ModalTraineeChooser() {
        function Trainee(props) {
            let t = props.t;
            if (t.id === traineeSelected) {
                return (
                    <li key={t.id} className='MainView_modal_menu_li_selected'>{t.name}</li>
                )
            }
            return (
                <li key={t.id} className='MainView_modal_menu_li' onClick={() => loadTrainee(t.id)}>{t.name}</li>
            )
        }
        const iterateTrainee = (traineeList) => {
            return traineeList.map((t) => (
                <Trainee t={t} key={t.id} />
            ))
        }

        if (trainee_choser) {
            return (
                <div className='MainView_modal_menu' onClick={() => setTraineeChooser(false)}>
                    <ul>
                        {iterateTrainee(trainees)}
                        <li key={-1} className='MainView_modal_menu_li' onClick={() => toAddTrainee()}><span className="material-symbols-outlined">add</span></li>
                    </ul>
                </div>
            )
        }

        return (
            <></>
        )
    }
}