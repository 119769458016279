
import './Basic.css'

export function Basic({ title, hide, buttons, children }) {

    return (
        <table className='Basic_table'>
            <tbody>
                <tr>
                    <td className='Basic_title'>{title}<span onClick={hide} className="material-symbols-outlined Basic_title_left">arrow_back</span></td>
                </tr>
                <tr>
                    <td className='Basic_body'>
                        <div className='Basic_body_div'>
                            <table  className='Basic_body_table'>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr><td className='Session_button'>
                    <div className='Session_button_div'>
                        {buttons}
                    </div>
                </td></tr>
            </tbody>
        </table>
    )
}

export function BasicInput({ title, setTitle, hide, buttons, children }) {

    return (
        <table className='Basic_table'>
            <tbody>
                <tr>
                    <td className='Basic_title'>
                    <input type='text' className='Basic_title_input' value={title} onChange={(e) => setTitle(e.target.value)} />
                        <span onClick={hide} className="material-symbols-outlined Basic_title_left">arrow_back</span>
                    </td>
                </tr>
                <tr>
                    <td className='Basic_body'>
                        <div className='Basic_body_div'>
                            <table  className='Basic_body_table'>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr><td className='Session_button'>
                    <div className='Session_button_div'>
                        {buttons}
                    </div>
                </td></tr>
            </tbody>
        </table>
    )
}