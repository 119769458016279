import { useState } from 'react';
import './EditSubcycle.css';
import DateObject from 'react-date-object';
import { db } from "../firebase.js";
import {uid} from 'uid';


export function EditSubcycle({ hide, subcycle, trainee, cycle}){
    const [name, setName] = useState(subcycle.name);
    const [loads, setLoads] = useState(subcycle.loads);
    const [physics, setPhysics] = useState(subcycle.physics);
    const [climbings, setClimbings] = useState(subcycle.climbings);
    const [technics, setTechnics] = useState(subcycle.technics);
    const [others, setOthers] = useState(subcycle.others);

    function saveAndExit(){
        db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(cycle.id).collection("SubCycles").doc(subcycle.id).update({
            name: name,
            loads: loads,
            physics: physics,
            climbings: climbings,
            technics: technics,
            others: others,
        })

        hide();
    }

    function updateLoads(i, load) {
        let l = [...loads];
        l[i] = load;
        setLoads(l);
    }

    function updatePhysicsName(i, n){
        let p = [...physics];
        p[i].name = n;
        setPhysics(p);
    }

    function updatePhysicsInfo(i, n){
        let p = [...physics];
        p[i].info = n;
        setPhysics(p);
    }

    function updatePhysicsTime(i, j, times) {
        let p = [...physics];
        let w = p[i].week;
        w[j] = times
        p[i].week = w;
        setPhysics(p);
    }

    function addPhysic() {
        let p = [...physics];
        p.push({
            id: uid(),
            name: "Nouvel objectif",
            info: "",
            week: Array(subcycle.weeks).fill(0),
        })
        setPhysics(p);
    }

    function removePhysic(i) {
        let p = [...physics];
        p.splice(i,1);
        setPhysics(p);
    }

    function updateClimbingsName(i, n){
        let p = [...climbings];
        p[i].name = n;
        setClimbings(p);
    }

    function updateClimbingsInfo(i, n){
        let p = [...climbings];
        p[i].info = n;
        setClimbings(p);
    }

    function updateClimbingsTime(i, j, times) {
        let p = [...climbings];
        let w = p[i].week;
        w[j] = times
        p[i].week = w;
        setClimbings(p);
    }

    function addClimbing() {
        let p = [...climbings];
        p.push({
            id: uid(),
            name: "Nouvel objectif",
            info: "",
            week: Array(subcycle.weeks).fill(0),
        })
        setClimbings(p);
    }

    function removeClimbing(i) {
        let p = [...climbings];
        p.splice(i,1);
        setClimbings(p);
    }

    function updateTechnicsName(i, n){
        let p = [...technics];
        p[i].name = n;
        setTechnics(p);
    }

    function updateTechnicsInfo(i, n){
        let p = [...technics];
        p[i].info = n;
        setTechnics(p);
    }

    function addTechnic() {
        let p = [...technics];
        p.push({
            id: uid(),
            name: "Nouvel objectif",
            info: "",
            week: Array(subcycle.weeks).fill(0),
        })
        setTechnics(p);
    }

    function removeTechnic(i) {
        let p = [...technics];
        p.splice(i,1);
        setTechnics(p);
    }

    function updateOthersName(i, n){
        let p = [...others];
        p[i].name = n;
        setOthers(p);
    }

    function updateOthersInfo(i, n){
        let p = [...others];
        p[i].info = n;
        setOthers(p);
    }

    function updateOthersTime(i, j, times) {
        let p = [...others];
        let w = p[i].week;
        w[j] = times
        p[i].week = w;
        setOthers(p);
    }

    function addOther() {
        let p = [...others];
        p.push({
            id: uid(),
            name: "Nouvel objectif",
            info: "",
            week: Array(subcycle.weeks).fill(0),
        })
        setOthers(p);
    }

    function removeOther(i) {
        let p = [...climbings];
        p.splice(i,1);
        setOthers(p);
    }

    let weeks = [];
    for(let i = 0; i < subcycle.weeks; i++){
        let d = (new DateObject(subcycle.start)).add(i*7, "days");
        let f = (new DateObject(d)).add(6, "days");

        weeks.push({
            start: d.format("DD/MM/YYYY"),
            end: f.format("DD/MM/YYYY"),
            weekNumber: d.weekOfYear,
        });
    }
    
    function Week({ week }) {
        return (
            <td className='EditSubcycle_week'>
                Semaine {week.weekNumber} <br />
                du {week.start} <br />
                au {week.end}
            </td>
        )
    }

    const iterateWeek = (weekList) => {
        return weekList.map((w) => (
            <Week key={uid()} week={w} />
        ))
    }

    const iterateLoads = () => {
        let cells = []
        for(let i = 0; i < loads.length; i++){
            cells.push(
                <td key={uid()} className='EditSubcycle_load'>
                    <select value={loads[i]} onChange={e => updateLoads(i, parseInt(e.target.value))}>
                        <option value={0}>Repos</option>
                        <option value={1}>Décharge</option>
                        <option value={2}>Développement</option>
                        <option value={3}>Reprise</option>
                        <option value={4}>Affutage</option>
                        <option value={5}>Performance</option>
                    </select>
                </td>
            )
        }
        return <tr  key={uid()}>{cells}</tr>
    }

    const iteratePhysics = () => {
        let rows = []
        for(let i = 0; i < physics.length; i++){
            rows.push(<tr key={physics[i].id + "-remove"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_remove'><button onClick={() => removePhysic(i)}><span className="material-symbols-outlined">remove</span></button></td></tr>);
            rows.push(<tr key={physics[i].id + "-name"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_name'><input type='text' placeholder="Nom Objectif" value={physics[i].name} onChange={e => updatePhysicsName(i, e.target.value)} /></td></tr>);
            rows.push(<tr key={physics[i].id + "-info"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_info'><input type='text' placeholder="Info Objectif" value={physics[i].info} onChange={e => updatePhysicsInfo(i, e.target.value)} /></td></tr>);
            let cells = [];
            for(let j= 0; j < subcycle.weeks; j++){
                cells.push(
                    <td key={physics[i].id + "-times" + j} className='EditSubcycle_times'>
                        <select value={physics[i].week[j]} onChange={e => updatePhysicsTime(i, j, parseInt(e.target.value))}>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select>
                    </td>
                )
            }
            rows.push(<tr key={physics[i].id + "-timerow"}>{cells}</tr>)
        }
        return <>{rows}</>
    }

    const iterateTechnics = () => {
        let rows = []
        for(let i = 0; i < technics.length; i++){
            rows.push(<tr key={technics[i].id + "-remove"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_remove'><button onClick={() => removeTechnic(i)}><span className="material-symbols-outlined">remove</span></button></td></tr>);
            rows.push(<tr key={technics[i].id + "-name"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_name'><input type='text' placeholder="Nom Objectif" value={technics[i].name} onChange={e => updateTechnicsName(i, e.target.value)} /></td></tr>);
            rows.push(<tr key={technics[i].id + "-info"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_info'><input type='text' placeholder="Info Objectif" value={technics[i].info} onChange={e => updateTechnicsInfo(i, e.target.value)} /></td></tr>);
        }
        return <>{rows}</>
    }

    const iterateOthers = () => {
        let rows = []
        for(let i = 0; i < others.length; i++){
            rows.push(<tr key={others[i].id + "-remove"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_remove'><button onClick={() => removeOther(i)}><span className="material-symbols-outlined">remove</span></button></td></tr>);
            rows.push(<tr key={others[i].id + "-name"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_name'><input type='text' placeholder="Nom Objectif" value={others[i].name} onChange={e => updateOthersName(i, e.target.value)} /></td></tr>);
            rows.push(<tr key={others[i].id + "-info"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_info'><input type='text' placeholder="Info Objectif" value={others[i].info} onChange={e => updateOthersInfo(i, e.target.value)} /></td></tr>);
            let cells = [];
            for(let j= 0; j < subcycle.weeks; j++){
                cells.push(
                    <td key={others[i].id + "-times" + j} className='EditSubcycle_times'>
                        <select value={others[i].week[j]} onChange={e => updateOthersTime(i, j, parseInt(e.target.value))}>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select>
                    </td>
                )
            }
            rows.push(<tr key={others[i].id + "-timesrow"}>{cells}</tr>)
        }
        return <>{rows}</>
    }

    const iterateClimbing = () => {
        let rows = []
        for(let i = 0; i < climbings.length; i++){
            rows.push(<tr key={climbings[i].id + "-remove"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_remove'><button onClick={() => removeClimbing(i)}><span className="material-symbols-outlined">remove</span></button></td></tr>);
            rows.push(<tr key={climbings[i].id + "-name"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_name'><input type='text' placeholder="Nom Objectif" value={climbings[i].name} onChange={e => updateClimbingsName(i, e.target.value)} /></td></tr>);
            rows.push(<tr key={climbings[i].id + "-info"}><td colSpan={subcycle.weeks} className='EditSubcycle_physics_info'><input type='text' placeholder="Info Objectif" value={climbings[i].info} onChange={e => updateClimbingsInfo(i, e.target.value)} /></td></tr>);
            let cells = [];
            for(let j= 0; j < subcycle.weeks; j++){
                cells.push(
                    <td key={climbings[i].id + "-times" + j} className='EditSubcycle_times'>
                        <select value={climbings[i].week[j]} onChange={e => updateClimbingsTime(i, j, parseInt(e.target.value))}>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select>
                    </td>
                )
            }
            rows.push(<tr key={climbings[i].id + "-timesrow"}>{cells}</tr>)
        }
        return <>{rows}</>
    }

    return (
        <div className='EditSubcycle'>
            <div className='EditSubcycle_body'>
                <table className='EditSubcycle_table'>
                    <tbody>
                        <tr><td className='EditSubcycle_table_title'>
                            <input className='EditSubcycle_table_title_input' type='text' value={name} onChange={e => setName(e.target.value)} />
                        </td></tr>
                        <tr><td className='EditSubcycle_table_body'><div  className='EditSubcycle_table_body_div'>
                            <table className='EditSubcycle_table2'>
                                <tbody>
                                    <tr>
                                       {iterateWeek(weeks)}
                                    </tr>
                                    {iterateLoads()}
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_title'>Renforcement Musculaire : </td></tr>
                                    {iteratePhysics()}
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_add'><button onClick={() => addPhysic()}><span className="material-symbols-outlined">add</span></button></td></tr>
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_title'>Escalades : </td></tr>
                                    {iterateClimbing()}
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_add'><button onClick={() => addClimbing()}><span className="material-symbols-outlined">add</span></button></td></tr>
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_title'>Techniques, Tactiques et Mentals : </td></tr>
                                    {iterateTechnics()}
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_add'><button onClick={() => addTechnic()}><span className="material-symbols-outlined">add</span></button></td></tr>
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_title'>Autres : </td></tr>
                                    {iterateOthers()}
                                    <tr><td colSpan={subcycle.weeks} className='EditSubcycle_physics_add'><button onClick={() => addOther()}><span className="material-symbols-outlined">add</span></button></td></tr>
                                    <tr>
                                        <td colSpan={subcycle.weeks}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div></td></tr>
                        <tr><td className='EditSubcycle_table_button'>
                        <div className='EditSubcycle_table_button_div'>
                            <button onClick={() => hide()}><span className="material-symbols-outlined">close</span></button>
                            <button onClick={() => saveAndExit()}><span className="material-symbols-outlined">save</span></button>
                        </div>
                        </td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}