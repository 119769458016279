import './EditionCycles.css'
import { useState, useEffect } from "react";
import DateObject from "react-date-object";
import { db } from "./firebase.js";
import { EditCycle } from "./EditionCycles/EditCycle.js"
import { ModalView } from './ModalView.js';
import { AddCycle } from './AddCycle.js';

export function EditionCycles({ hide, trainee }) {
    const State = {
        ListCycles: 'ListCycles',
        AddCycle: 'AddCycle',
        EditCycle: 'EditCycle',
    };

    const [add_cycle, setAddCycle] = useState(false);

    const [state, setState] = useState(State.ListCycles);
    const [cycle_to_edit, setCycleToEdit] = useState(null);

    const [cycles, setCycles] = useState([]);

    function editCycle(c) {
        setCycleToEdit(c);
        setState(State.EditCycle);
    }

    function reset() {
        setCycleToEdit(null);
        setState(State.ListCycles);
    }

    function showAddCycle() {
        setAddCycle(true);
    }

    function hideAddCycle() {
        setAddCycle(false);
    }

    function deleteCycle(c) {
        if (window.confirm('Voulez vous vraiment supprimer ce sous cycle? (cette action est irréversible)')) {
            db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(c.id).collection("SubCycles").get().then((querySnapshot) => {
                let s = querySnapshot.size;
                let a = 0
                querySnapshot.forEach((doc) => {
                    db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(c.id).collection("SubCycles").doc(doc.id).delete().then(() => {
                        console.log("Document successfully deleted!");
                        a++;
                        if (a === s) {
                            db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(c.id).delete().then(() => {
                                console.log("Document successfully deleted!");
                            }).catch((error) => {
                                console.error("Error removing document: ", error);
                            });
                        }
                    }).catch((error) => {
                        console.error("Error removing document: ", error);
                    });
                });
                if (a === s) {
                    db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(c.id).delete().then(() => {
                        console.log("Document successfully deleted!");
                    }).catch((error) => {
                        console.error("Error removing document: ", error);
                    });
                }
            })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });

            /*db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(c.id).delete().then(() => {
                console.log("Document successfully deleted!");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });*/

        } else {

        }
    }


    useEffect(() => {
        if (trainee != null) {
            db.collection("Trainees").doc(trainee.id).collection("Cycles")
                .onSnapshot((snapshot) => {
                    let a = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        name: doc.data().name,
                        debut: new DateObject(doc.data().debut),
                        fin: (new DateObject(doc.data().fin)).add(6, "d").setHour(23).setMinute(59).setSecond(59),
                        sousCycles: doc.data().sousCycles,
                        semaines: doc.data().semaines,
                    }))
                    setCycles(a);
                });
        }
    }, [trainee]);

    function Cycle({ cycle }) {
        return (
            <tr>
                <td className='EditionCycles_list_tab_cycle'>
                    <b>{cycle.name}</b> &nbsp; <br />
                    <i>du</i>&nbsp;{cycle.debut.format("DD/MM/YY")} &nbsp;<i>au</i>&nbsp;  {cycle.fin.format("DD/MM/YY")} <br />
                    <button onClick={() => editCycle(cycle)}><span className="material-symbols-outlined">edit</span></button>
                    <button onClick={() => deleteCycle(cycle)}><span className="material-symbols-outlined">delete</span></button>
                </td>
            </tr>
        )
    }

    const iterateCycle = (list) => {
        return list.map((c) => (
            <Cycle key={c.id} cycle={c} />
        ))
    }

    if (state === State.EditCycle && cycle_to_edit != null) {
        return (
            <EditCycle trainee={trainee} cycle={cycle_to_edit} hide={reset} />
        )
    }

    return (
        <div className="EditionCycles">
            <table className='EditionCycles_list_table'>
                <tbody>
                    <tr>
                        <td className='EditionCycles_list_tab_title'>Cycles de {trainee.name}<span onClick={hide} className="material-symbols-outlined EditionCycles_cell_title_left">arrow_back</span><span onClick={showAddCycle} className="material-symbols-outlined EditionCycles_cell_title_right">add</span></td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                    {iterateCycle(cycles)}
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td className='EditionCycles_list_tab_end'></td>
                    </tr>
                </tbody>
            </table>
            <ModalView isVisible={add_cycle}><AddCycle onClose={hideAddCycle} cycles={cycles} trainee={trainee} /></ModalView>
        </div>
    )
}