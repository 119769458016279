import './App.css';
import { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { auth } from "./firebase.js";
import { MainView } from './MainView.js';

function App() {
  const [isConnected, setIsConnected] = useState(false);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  function connectWithMail() {
    auth.signInWithEmailAndPassword(login, password)
      .then((userCredential) => {
        // Signed in
        //var user = userCredential.user;

      })
      .catch((error) => {
        console.log("error code: " + error.code);
        console.log("error message: " + error.message);
      });
    setLogin("");
    setPassword("");
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsConnected(true);
        //console.log("Connected");
      } else {
        setIsConnected(false);
        //console.log("Disconnected");
      }
    });
    const unloadCallback = () => { firebase.app().delete() }
    window.addEventListener("beforeunload", unloadCallback);
    return async () => {
      window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [])

  if(isConnected){
    return (
      <MainView />
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className='App_identification_title'>
          Planif
        </div>
      </header>
      <div className="App-body">
        <table className='App_identification_table'>
          <tbody>
            <tr>
              <td className='App_identification_cell_input'><input type="text" placeholder="Adresse mail" value={login} onInput={e => setLogin(e.target.value)}></input></td>
            </tr>
            <tr>
              <td className='App_identification_cell_input'><input type="password" placeholder="Password" value={password} onInput={e => setPassword(e.target.value)}></input></td>
            </tr>
            <tr>
              <td>
                <button onClick={connectWithMail}>Se connecter</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
