import './EditCycle.css'
import '../DataToolTip.css'
import { useState, useEffect, useRef } from "react";
import DateObject from "react-date-object";
import { db } from "../firebase.js";
import { uid } from 'uid';
//import { useMediaQuery } from 'react-responsive';
import { EditSubcycle } from './EditSubcycle.js';
import { AddSubcycle } from './AddSubcycle.js';

export function EditCycle({ trainee, cycle, hide }) {

    //const isBig = useMediaQuery({ query: `(min-width: 1000px)` });

    const subcycleListener = useRef(null);
    const [subcycles, setSubcycles] = useState([]);

    const [add_subcycle, setAddSubcycle] = useState(false);
    const [add_subcycle_start, setAddSubcycleStart] = useState(new DateObject());
    const [add_subcycle_nb, setAddSubcycleNb] = useState(0);

    const [edit_subcycle, setEditSubcycle] = useState(false);
    const [subcycle_to_edit, setSubcycleToEdit] = useState(null);

    function editSubcycle(subcycle){
        setSubcycleToEdit(subcycle);
        setEditSubcycle(true);
    }

    function finnishEditSubcycle(){
        setSubcycleToEdit(null);
        setEditSubcycle(false);
    }

    function addSubcycle(s, n ){
        setAddSubcycleStart(s);
        setAddSubcycleNb(n);
        setAddSubcycle(true);
    }

    function finnishAddSubcycle(){
        setAddSubcycle(false);
    }


    useEffect(() => {
        if (cycle != null) {
            if (subcycleListener.current != null) {
                subcycleListener.current();
            }
            subcycleListener.current = db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(cycle.id).collection("SubCycles")
                .onSnapshot((snapshot) => {
                    let a = snapshot.docs.map((doc) => ({
                        id: doc.id,
                        name: doc.data().name,
                        start: new DateObject(doc.data().start),
                        end: (new DateObject(doc.data().end)).setHour(23).setMinute(59).setSecond(59),
                        weeks: doc.data().weeks,
                        loads: doc.data().loads,
                        physics: doc.data().physics,
                        climbings: doc.data().climbings,
                        technics: doc.data().technics,
                        others: doc.data().others,
                    }))
                    a.sort((a, b) => {
                        if (a.start < b.start) {
                            return -1;
                        } else if (a.start > b.start) {
                            return 1;
                        }

                        return 0;
                    });
                    setSubcycles(a);
                });
        }
    }, [trainee, cycle]);

    function end() {
        if (subcycleListener.current != null) {
            subcycleListener.current();
        }
        hide();
    }

    let week_temp = new DateObject(cycle.debut);
    let last_day = new DateObject(cycle.fin);

    //let colspan = last_day.toDays() - week_temp.toDays() + 1;//dayBetween(cycle.debut, last_day)+2; 

    //Gestion Semaine
    let weeks = [];
    while (week_temp < last_day) {
        let s = new DateObject(week_temp);
        let e = (new DateObject(week_temp)).add(6, "days");

        weeks.push({
            weekNumber: week_temp.weekOfYear,
            start: s.format("DD/MM/YYYY"),
            end: e.format("DD/MM/YYYY"),
        });
        week_temp = week_temp.add(7, "days");
    }

    //Gestion Subcycles
    week_temp = new DateObject(cycle.debut);
    let scs = [];
    for (let i = 0; i < subcycles.length; i++) {
        let d = new DateObject(subcycles[i].start);
        let f = new DateObject(subcycles[i].end);

        if (week_temp < d) {
            let weeks = (d.toDays() - week_temp.toDays()) / 7

            scs.push({
                weeks: weeks,
                start: week_temp.format(),
                subcycle: null,
            })
        }

        scs.push({
            weeks: subcycles[i].weeks,
            start: d.format(),
            subcycle: subcycles[i],
        })

        week_temp = (new DateObject(f)).add(1, "days");
    }

    if (week_temp < last_day) {
        let weeks = (last_day.toDays() - week_temp.toDays() + 1) / 7

        scs.push({
            weeks: weeks,
            start: week_temp.format(),
            subcycle: null,
        })
    }

    function Week({ week }) {
        return (
            <td colSpan={7} className='EditCycle_principal_week'>
                Semaine {week.weekNumber} <br />
                du {week.start} <br />
                au {week.end}
            </td>
        )
    }

    const iterateWeek = (weekList) => {
        return weekList.map((w) => (
            <Week key={uid()} week={w} />
        ))
    }

    function deleteSubcycle(id){
        if (window.confirm('Voulez vous vraiment supprimer ce sous cycle? (cette action est irréversible)')) {
            db.collection("Trainees").doc(trainee.id).collection("Cycles").doc(cycle.id).collection("SubCycles").doc(id).delete().then(() => {
                console.log("Document successfully deleted!");
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });

        } else {
            
        }
    }

    function Subcycle({ subcycle }) {
        if (subcycle.subcycle != null) {
            function WeekLoad({ w }) {
                if (w === 0) {
                    return (
                        <td className='EditCycle_principal_subcycle_load EditCycle_principal_subcycle_load_rest'>Repos</td>
                    )
                }

                if (w === 1) {
                    return (
                        <td className='EditCycle_principal_subcycle_load EditCycle_principal_subcycle_load_decharge'>Décharge</td>
                    )
                }

                if (w === 2) {
                    return (
                        <td className='EditCycle_principal_subcycle_load EditCycle_principal_subcycle_load_low'>Entrainement<br />Faible</td>
                    )
                }

                return (
                    <td className='EditCycle_principal_subcycle_load'>?</td>
                )
            }

            const iterateWeekLoad = (list) => {
                return list.map((e) => (
                    <WeekLoad key={uid()} w={e} />
                ))
            }

            function Physic({ p, weeks }) {
                const iterate = (list) => {
                    return list.map((e) => (
                        <td  key={uid()} className='EditCycle_principal_subcycle_nbbyweek'>{e}</td>
                    ))
                }
                return(
                    <>
                        <tr>
                            <td colSpan={weeks} className='EditCycle_principal_subcycle_subsubname'>
                                {p.name} 
                                { p.info === "" ?
                                    <></>
                                    :
                                    <span  infoBulle={p.info} className="material-symbols-outlined">info</span>
                                }
                            </td>
                        </tr>
                        <tr>
                            {iterate(p.week)}
                        </tr>
                    </>
                )
            }

            const iteratePhysic = (list, weeks) => {
                return list.map((e) => (
                    <Physic key={uid()} p={e} weeks={weeks} />
                ))
            }

            function Technic({ p, weeks}) {
                return(
                    <>
                        <tr>
                            <td colSpan={weeks} className='EditCycle_principal_subcycle_subsubname'>
                                {p.name} 
                                { p.info === "" ?
                                    <></>
                                    :
                                    <span  infoBulle={p.info} className="material-symbols-outlined">info</span>
                                }
                            </td>
                        </tr>
                    </>
                )
            }

            const iterateTechnic = (list, weeks) => {
                return list.map((e) => (
                    <Technic key={uid()} p={e} weeks={weeks} />
                ))
            }

            return (
                <td colSpan={7 * subcycle.weeks} className='EditCycle_principal_subcycle'>
                    <table className='EditCycle_principal_subcycle_table'>
                        <tbody>
                            <tr>
                                <td className='EditCycle_principal_subcycle_name' colSpan={subcycle.weeks}>
                                    {subcycle.subcycle.name}
                                </td>
                            </tr>
                            <tr>
                                {iterateWeekLoad(subcycle.subcycle.loads)}
                            </tr>
                            {
                                (subcycle.subcycle.physics.length > 0 ? 
                                    (<>
                                        <tr>
                                            <td className='EditCycle_principal_subcycle_subname' colSpan={subcycle.weeks}>
                                                Renforcement musculaire :
                                            </td>
                                        </tr>
                                        {iteratePhysic(subcycle.subcycle.physics, subcycle.weeks)}
                                    </>)
                                    :
                                    (<></>)
                                )
                            }
                            {
                                (subcycle.subcycle.climbings.length > 0 ? 
                                    (<>
                                        <tr>
                                            <td className='EditCycle_principal_subcycle_subname' colSpan={subcycle.weeks}>
                                                Escalades :
                                            </td>
                                        </tr>
                                        {iteratePhysic(subcycle.subcycle.climbings, subcycle.weeks)}
                                    </>)
                                    :
                                    (<></>)
                                )
                            }
                            {
                                (subcycle.subcycle.technics.length > 0 ? 
                                    (<>
                                        <tr>
                                            <td className='EditCycle_principal_subcycle_subname' colSpan={subcycle.weeks}>
                                                Techniques, Tactiques, Mentals :
                                            </td>
                                        </tr>
                                        {iterateTechnic(subcycle.subcycle.technics, subcycle.weeks)}
                                    </>)
                                    :
                                    (<></>)
                                )
                            }
                            {
                                (subcycle.subcycle.others.length > 0 ? 
                                    (<>
                                        <tr>
                                            <td className='EditCycle_principal_subcycle_subname' colSpan={subcycle.weeks}>
                                                Autres :
                                            </td>
                                        </tr>
                                        {iteratePhysic(subcycle.subcycle.others, subcycle.weeks)}
                                    </>)
                                    :
                                    (<></>)
                                )
                            }
                            <tr>
                                <td colSpan={subcycle.weeks}></td>
                            </tr>
                            <tr className='EditCycle_principal_subcycle_button'>
                                <td colSpan={subcycle.weeks}>
                                    <button onClick={(e) => editSubcycle(subcycle.subcycle, e)}><span className="material-symbols-outlined">edit</span></button>
                                    <button  onClick={(e) => deleteSubcycle(subcycle.subcycle.id, e)}><span className="material-symbols-outlined">delete</span></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            )
        }

        return (
            <td colSpan={7 * subcycle.weeks} className='EditCycle_principal_subcycle_empty' onClick={() => addSubcycle(subcycle.start, subcycle.weeks)}>
                Pas encore de sous cycle <br />
                sur cette période. <br />
                <i>Cliquer dessus pour <br/> ajouter un sous cycle.</i>
            </td>
        )
    }

    const iterateSubcycle = (list) => {
        return list.map((s) => (
            <Subcycle key={uid()} subcycle={s} />
        ))
    }

    function BigScreen() {
        return (
            <div className="EditCycle">
                <table className='EditCycle_table'>
                    <tbody>
                        <tr>
                            <td className='EditCycle_title'>
                                <b>{cycle.name}</b><br />
                                <i>du {cycle.debut.format("DD/MM/YYYY")} au {cycle.fin.format("DD/MM/YYYY")}</i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='EditCycle_principal_div'>
                                    <table className='EditCycle_principal_table'>
                                        <tbody>
                                            <tr>
                                                {iterateWeek(weeks)}
                                            </tr>
                                            <tr>
                                                {iterateSubcycle(scs)}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='EditCycle_button'>
                                <button onClick={() => end()}><span className="material-symbols-outlined">close</span></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {edit_subcycle ?
                    <EditSubcycle subcycle={subcycle_to_edit} hide={finnishEditSubcycle} trainee={trainee} cycle={cycle}/>
                :
                    <></>
                }
                {add_subcycle ?
                    <AddSubcycle start={add_subcycle_start} numberOfWeek={add_subcycle_nb} hide={finnishAddSubcycle} trainee={trainee} cycle={cycle}/>
                :
                    <></>
                }
            </div>
        )
    }

    /*function SmallScreen() {
        return (
            <div className="EditionCycles">
                <table className='EditCycle_table'>
                    <tbody>
                        <tr>
                            <td className='EditCycle_title'>
                                <b>{cycle.name}</b><br />
                                <i>du {cycle.debut.format("DD/MM/YYYY")} au {cycle.fin.format("DD/MM/YYYY")}</i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='ModalUpdateCycle_principal_div'>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='EditCycle_button'>
                                <button onClick={() => end()}><span className="material-symbols-outlined">close</span></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <EditSubcycle subcycle={subcycle_to_edit} isVisible={edit_subcycle} hide={finnishEditSubcycle} trainee={trainee} cycle={cycle}/>
            </div>
        )
    }*/

    function ScreenChooser() {
        return (
            <BigScreen />
        )
        /*if (isBig) {
            return (
                <BigScreen />
            )
        }

        return (
            <SmallScreen />
        )*/
    }


    return (
        <ScreenChooser />
    )
}