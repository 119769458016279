
import './Session.css'
import { useRef, forwardRef } from 'react';

import { useReactToPrint } from "react-to-print";
import { Basic } from './Basic';


export function CompleteSession({ session, hide, showEdit, copySession, subcycle_session, trainee }) {

    function timeToString(t) {
        let s = "";
        let m = t;
        let h = Math.floor(m / 60.0);
        m -= h * 60;

        if (h > 0) {
            s += h + "h";
            if (m > 0) {
                s += m;
            }
        } else {
            s += m + "min";
        }

        return s;
    }

    function edit() {
        hide();
        showEdit(session);
    }

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        contentRef: componentRef,
    });

    function Exercice({ exercice }) {
        if (exercice.description === "" || exercice.description === "<p></p>\n") {
            return (
                <>
                    <tr>
                        <td className='Session_exercice_title'>{exercice.name}</td>
                    </tr>
                    <tr>
                        <td className='Session_exercice_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(exercice.time)}</td>
                    </tr>
                    <tr>
                        <td className='Session_exercice_space'></td>
                    </tr>
                </>
            )
        }

        return (
            <>
                <tr>
                    <td className='Session_exercice_title'>{exercice.name}</td>
                </tr>
                <tr>
                    <td className='Session_exercice_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(exercice.time)}</td>
                </tr>
                <tr>
                    <td className='Session_exercice_time'><span className='Colonne_session_subtitle'>Description</span> :</td>
                </tr>
                <tr>
                    <td className='Session_exercice_description' dangerouslySetInnerHTML={{ __html: exercice.description }}></td>
                </tr>
                <tr>
                    <td className='Session_exercice_space'></td>
                </tr>
            </>
        )
    }

    const iterateExercice = (list) => {
        return list.map((e) => (
            <Exercice key={e.id} exercice={e} />
        ))
    }

    const PrintSession = forwardRef((props, ref) => (
        <div className='PrintSession' ref={ref}>
            <table className='PrintSession_table'>
                <tbody>
                    <tr>
                        <td className='Session_title'>{session.name}</td>
                    </tr>
                    <tr>
                        <td className='Session_date'>Le {session.date.format("DD/MM/YYYY")} à {session.date.format("HH")}h{session.date.format("mm")}</td>
                    </tr>
                    <tr>
                        <td className='Session_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(session.time)}</td>
                    </tr>
                    <tr>
                        <td className='Session_time'><span className='Colonne_session_subtitle'>Exercices</span> :</td>
                    </tr>

                    {iterateExercice(session.exercices)}

                    <tr>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    ));

    return (
        <Basic title={session.name} hide={hide} buttons={
            <>
                <button onClick={() => { edit() }}><span className="material-symbols-outlined">edit</span></button>
                <button onClick={() => { copySession() }}><span className="material-symbols-outlined">content_copy</span></button>
                <button onClick={() => handlePrint()}><span className="material-symbols-outlined">print</span></button>
            </>
        }>
            <>
                <tr>
                    <td className='Session_date'>Le {session.date.format("DD/MM/YYYY")} à {session.date.format("HH")}h{session.date.format("mm")}</td>
                </tr>
                <tr>
                    <td className='Session_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(session.time)}</td>
                </tr>
                <tr>
                    <td className='Session_time'><span className='Colonne_session_subtitle'>Exercices</span> :</td>
                </tr>

                {iterateExercice(session.exercices)}
                <tr>
                    <td className='Session_test'>
                        <div className='Session_hide'>
                            <PrintSession ref={componentRef} />
                        </div>
                    </td>
                </tr>
            </>
        </Basic>
    )

    /*return (
        <table className='Session_table'>
            <tbody>
                <tr>
                    <td className='Session_title'>{session.name}<span onClick={hide} className="material-symbols-outlined Session_title_left">arrow_back</span></td>
                </tr>
                <tr>
                    <td className='Session_body'>
                        <div className='Session_body_div'>
                            <table  className='Session_body_table'>
                                <tbody>
                                    <tr>
                                        <td className='Session_date'>Le {session.date.format("DD/MM/YYYY")} à {session.date.format("HH")}h{session.date.format("mm")}</td>
                                    </tr>
                                    <tr>
                                        <td className='Session_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(session.time)}</td>
                                    </tr>
                                    <tr>
                                        <td className='Session_time'><span className='Colonne_session_subtitle'>Exercices</span> :</td>
                                    </tr>

                                    {iterateExercice(session.exercices)}
                                </tbody>
                            </table>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td className='Session_test'>
                        <div className='Session_hide'>
                            <PrintSession ref={componentRef} />
                        </div>
                    </td>
                </tr>

                <tr><td className='Session_button'>
                    <div className='Session_button_div'>
                        <button onClick={() => { edit() }}><span className="material-symbols-outlined">edit</span></button>
                        <button onClick={() => handlePrint()}><span className="material-symbols-outlined">print</span></button>
                    </div>
                </td></tr>
            </tbody>
        </table>
    )*/
}


/*export function PrintSession({ session, ref }) {

    function timeToString(t) {
        let s = "";
        let m = t;
        let h = Math.floor(m / 60.0);
        m -= h * 60;

        if (h > 0) {
            s += h + "h";
            if (m > 0) {
                s += m;
            }
        } else {
            s += m + "min";
        }

        return s;
    }

    function Exercice({ exercice }) {
        if (exercice.description === "" || exercice.description === "<p></p>\n") {
            return (
                <>
                    <tr>
                        <td className='Session_exercice_title'>{exercice.name}</td>
                    </tr>
                    <tr>
                        <td className='Session_exercice_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(exercice.time)}</td>
                    </tr>
                    <tr>
                        <td className='Session_exercice_space'></td>
                    </tr>
                </>
            )
        }

        return (
            <>
                <tr>
                    <td className='Session_exercice_title'>{exercice.name}</td>
                </tr>
                <tr>
                    <td className='Session_exercice_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(exercice.time)}</td>
                </tr>
                <tr>
                    <td className='Session_exercice_time'><span className='Colonne_session_subtitle'>Description</span> :</td>
                </tr>
                <tr>
                    <td className='Session_exercice_description' dangerouslySetInnerHTML={{ __html: exercice.description }}></td>
                </tr>
                <tr>
                    <td className='Session_exercice_space'></td>
                </tr>
            </>
        )
    }

    const iterateExercice = (list) => {
        return list.map((e) => (
            <Exercice key={e.id} exercice={e} />
        ))
    }

    return (
        <div className='PrintSession' ref={ref}>
            <table className='PrintSession_table'>
                <tbody>
                    <tr>
                        <td className='Session_title'>{session.name}</td>
                    </tr>
                    <tr>
                        <td className='Session_time'><span className='Colonne_session_subtitle'>Durée</span> : {timeToString(session.time)}</td>
                    </tr>
                    <tr>
                        <td className='Session_time'><span className='Colonne_session_subtitle'>Exercices</span> :</td>
                    </tr>

                    {iterateExercice(session.exercices)}

                    <tr>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}*/